<template>
  <div class="scan-result-container">
    <div class="space box-p-1"></div>
    <div class="card">
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="main-title" slot="title">
            取货点：{{ pickInfo.store_name }}
          </div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">订单编码：</div>
          <div class="values">{{ pickInfo.flow_up_code }}</div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">联 系 人 ：</div>
          <div class="values">{{ pickInfo.lianxiren }}</div>
        </cell-v3>
      </div>
      <div class="card-line bottom-border">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">联系电话：</div>
          <div class="values">{{ pickInfo.mobile }}</div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="main-title" slot="title">
            配送点：{{ pointsList.site_name }}
          </div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">负 责 人 ：</div>
          <div class="values">{{ pointsList.site_leader }}</div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">联系电话：</div>
          <div class="values">{{ pointsList.sp_mobile }}</div>
        </cell-v3>
      </div>
      <div class="card-line" style="margin-top: 20px">
        <cell-v3 :hide-icon="true">
          <div class="scan-button" @click="hide = !hide">
            订单详情
            <img
              v-if="hide"
              :src="require('@/static/image/icons/arrowUp.png')"
            />
            <img v-else :src="require('@/static/image/icons/arrowDown.png')" />
          </div>
        </cell-v3>
      </div>
      <div class="goods-list" v-show="hide">
        <div class="goods" v-for="(item, index) in goodList" :key="index">
          <goods-v4
            :img-url="item.cover"
            :number="item.number"
            :title="item.title"
          />
        </div>
      </div>

      <!--<div class="card-line" style="margin-top: 15px;">
                <cell-v3 :hide-icon="true">
                    <div class="title" slot="title">联 系 人 ：张三</div>
                </cell-v3>
            </div>
            <div class="card-line">
                <cell-v3 :hide-icon="true">
                    <div class="title" slot="title">联系方式：13489278348</div>
                    <div class="actions">
                        <img :src="require('@/static/image/icons/mobile.png')"/>
                        联系他
                    </div>
                </cell-v3>
            </div>-->
    </div>
    <div class="bottom-buttons">
      <div class="button-left" @click="goHome">返回</div>
      <div class="button-right" @click="makeSure">确定</div>
    </div>
  </div>
</template>

<script>
import CellV3 from "../../template/community/cell/cellV3";
import GoodsV4 from "../../template/community/goods/GoodsV4";

export default {
  name: "scan-result",
  components: { GoodsV4, CellV3 },
  data() {
    return {
      hide: true,
      flow_up_code: 0,
      orderInfo: [],
      pickInfo: [],
      pointsList: [],
      goodList: [],
    };
  },
  created() {
    if (typeof this.$route.query.flow_up_code != "undefined") {
      this.flow_up_code = this.$route.query.flow_up_code;
    }
    this.getOrderInfo();
  },
  methods: {
    async getOrderInfo() {
      try {
        let params = {
          flow_up_code: this.flow_up_code,
        };

        let query = await this.$api.community.courier.geScanInfo(params);
        this.orderInfo = query.data;
        this.pickInfo = query.data.pickInfo;
        this.pointsList = query.data.pointsList;
        this.goodList = query.data.goodList;
      } catch (error) {
        this.$toast.fail(error.message);
      }
    },
    goHome: function () {
      if (this.$platform.wxsdk.isWechat()) {
        // this.$platform.wxsdk.wxRoute({ type: "navigateBack" });
        this.$platform.wxsdk.wxRoute({
          type: "redirectTo",
          url: "/web/courier/courier-home",
        });
      } else {
        this.$router.push({ path: "courier-home" });
      }
    },
    async makeSure() {
      if (!this.orderInfo.canMake) {
        this.$toast.fail("此码状态不能修改");
        return true;
      }
      try {
        let params = {
          flow_up_code: this.flow_up_code,
          delivery_status: 1,
        };

        let query = await this.$api.community.courier.makeSure(params);
        this.orderInfo.canMake = true;
        let self = this;
        self.$dialog({
          title: "温馨提示",
          content: "取货核销成功",
          closeBtn: false, //显式右上角关闭按钮
          noCancelBtn: true,
          onOkBtn() {
            self.$dialog.closed();
            self.goHome();
          },
        });
      } catch (error) {
        let self = this;
        self.$dialog({
          title: "温馨提示",
          content: error.message || error,
          closeBtn: false, //显式右上角关闭按钮
          noCancelBtn: true,
          onOkBtn() {
            self.$dialog.closed();
            self.goHome();
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.scan-result-container {
  width: 100%;
  min-height: 100vh;
  background: #f6f6f6;
  position: relative;

  .bottom-buttons {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;

    .button-left {
      width: 166px;
      height: 44px;
      background: #ffece1;
      border-radius: 3px;
      color: #f46a17;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }

    .button-right {
      width: 166px;
      height: 44px;
      background: linear-gradient(
        125deg,
        #fab476 0%,
        #eb6100 100%,
        #eb6100 100%
      );
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
    }
  }


  .card {
    background: #fff;
    margin: 15px;
    padding: 15px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 59px;

    .main-title {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }

    .title,
    .values {
      color: #333333;
      font-size: 14px;
    }

    .card-line {
      margin-bottom: 9px;

      .scan-button {
        color: #f46a17;
        font-size: 14px;

        img {
          width: 14px;
          height: 14px;
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        color: #f46a17;

        img {
          margin-right: 5px;
          width: 14px;
          height: 14px;
        }
      }
    }

    .bottom-border {
      padding: 0 0 15px 0;
      border-bottom: #ebebeb solid 1px;
      margin-bottom: 20px;
    }

    .goods-list {
      width: 100%;
      border-top: #ebebeb solid 1px;
      border-bottom: #ebebeb solid 1px;
      padding-bottom: 15px;
      margin-top: 15px;

      .goods {
        margin-top: 15px;
      }
    }

    .money {
      margin-top: 15px;
      font-size: 16px;
      color: #c10000;
      font-weight: bold;
    }
  }
}
</style>
